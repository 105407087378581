.bg-color {
  background-color: black;
  height: 100vh;
}
@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}

.nnc {
  animation: zoomOut 1s ease-in-out;
}


.login-container {
  display: flex;
  height: 100vh;
}

.left-side {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.left-side img {
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
}

.right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin-bottom: 5px;
}

input {
  margin-bottom: 15px;
  padding: 8px;
  width: 100%;
}


